import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, PageProps } from 'gatsby';
import { Box, Grid, Typography } from '@mui/material';
import StandardFrame from '../../components/StandardFrame';
import PostHero from '../../components/Heros/PostHero';
import ArticleStyledHtml from '../../components/ArticleStyledHtml';
import StandardBreadcrumbs from '../../components/StandardBreadcrumbs';
import { height } from '../../components/ResponsiveAppBar';
import theme from '../../../theme';
import PageSection from '../../components/Sections/PageSection';
import PageSectionTitle from '../../components/Sections/PageSectionTitle';
import AdvantageSectionContent from '../../components/Sections/Advantages/AdvantageSectionContent';
import PageSectionActionButton from '../../components/Sections/PageSectionActionButton';
import AppBarOffsetedScrollContainer from '../../components/AppBarOffsetedScrollContainer';
import { DemoFormWithBorder } from '../../components/DemoForm';
import { RodoLink } from '../../components/RodoLink';

export const pageQuery = graphql`
  query Persona($id: String!) {
    current: markdownRemark(childPersona: { id: { eq: $id } }) {
      ...DemoFormData
      fields {
        slug
      }
      html
      frontmatter {
        order
        title
        description
        card_image {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    advantage: allPrzewagiYaml(filter: { tags: { in: ["general"] } }) {
      edges {
        node {
          id
          title
          description
        }
      }
    }
  }
`;

export default ({ data: { current, advantage } }: PageProps<Queries.PersonaQuery>) => {
  return (
    <>
      <Helmet title={current!.frontmatter!.title!} />
      <StandardFrame>
        <PostHero
          maxWidth='lg'
          slug={current!.fields!.slug!}
          title={current!.frontmatter!.title!}
          summary={current!.frontmatter!.description!}
          backgroundImage={current!.frontmatter!.card_image!.childImageSharp!.gatsbyImageData!}
        />

        <AppBarOffsetedScrollContainer maxWidth='lg' id='demo'>
          <StandardBreadcrumbs
            path={[
              { title: 'Demo', link: '/demo' },
              {
                title: current!.frontmatter!.title!,
                link: current!.fields!.slug!
              }
            ]}
          />

          {/* Grid nie robi tutaj margin collapse z Typography, więc mt=3-2=1. */}
          <Grid container mt={3} mb={3} columnSpacing={8}>
            <Grid item xs={12} md={7}>
              <ArticleStyledHtml html={current!.html!} />
            </Grid>
            <Grid item xs={12} md={5}>
              <Box position='sticky' top={`calc(${height} + ${theme.spacing(3)})`} my={3}>
                <Box mx='auto' width={current!.frontmatter!.demo_form_width!}>
                  <Typography
                    variant='h4'
                    component='h2'
                    textTransform='uppercase'
                    textAlign='center'
                    color='divider'
                    mx='auto'
                    width={current!.frontmatter!.demo_form_width!}
                  >
                    Sprawdź jak Mediporta może wyglądać u Ciebie
                  </Typography>
                  <Typography variant='body2' component='p' my={2}>
                    Wypełnienie formularza kontaktowego i jego wysłanie równoznaczne jest ze zgodą na przetwarzanie
                    danych osobowych w celu otrzymania dostępu do wersji demonstracyjnej systemu oraz dalszego kontaktu
                    w tym zakresie zgodnie z <RodoLink>Polityką prywatności</RodoLink>.
                  </Typography>
                </Box>
                <DemoFormWithBorder
                  src={current!.frontmatter!.demo_form!}
                  width={current!.frontmatter!.demo_form_width!}
                  height={current!.frontmatter!.demo_form_height!}
                />
              </Box>
            </Grid>
          </Grid>
        </AppBarOffsetedScrollContainer>

        <PageSection bgcolor='tertiary.main' maxWidth='lg'>
          <PageSectionTitle>Dlaczego warto wybrać Mediportę</PageSectionTitle>
          <AdvantageSectionContent
            items={advantage.edges.map(({ node }) => ({
              id: node!.id!,
              title: node!.title!,
              description: node!.description!
            }))}
            spacing={3}
            summaryProps={{}}
          />
          <PageSectionActionButton to='#demo'>Sprawdź jak Mediporta może wyglądać u Ciebie</PageSectionActionButton>
        </PageSection>
      </StandardFrame>
    </>
  );
};
