import React from 'react';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import { Breakpoint, Typography } from '@mui/material';
import Hero from './Hero';

export interface PostHeroProps {
  backgroundImage: IGatsbyImageData;
  maxWidth?: Breakpoint;
  slug: string;
  title: string;
  date?: string;
  summary?: string;
}

export default (props: PostHeroProps) => (
  <Hero
    minHeight={'250px'}
    maxWidth={props.maxWidth}
    image={
      <GatsbyImage
        // Kolor musi być ustawiony przez prop, bo on nadpisuje style.
        style={{ height: '100%' }}
        backgroundColor='#4E9CCC'
        // https://www.w3schools.com/cssref/playdemo.asp?filename=playcss_mix-blend-mode
        imgStyle={{ mixBlendMode: 'multiply' }}
        image={props.backgroundImage}
        alt=''
      />
    }
  >
    <Typography
      variant='h2'
      component='h1'
      textTransform='uppercase'
      color='white'
      gutterBottom
      sx={{
        maxWidth: { sm: '70%' }
      }}
    >
      {props.title}
    </Typography>

    {props.date && (
      <Typography variant='caption' textTransform='uppercase' color='white'>
        {props.date}
      </Typography>
    )}
    {props.summary && (
      <Typography variant='h5' component='p' color='white'>
        {props.summary}
      </Typography>
    )}
  </Hero>
);
