import { Box, BoxProps } from '@mui/material';
import React from 'react';

export default (props: { my?: BoxProps['my']; html: string }) => (
  <Box
    my={props.my}
    dangerouslySetInnerHTML={{ __html: props.html }}
    sx={{
      h3: {
        typography: 'h4',
        marginY: 3,
        textTransform: 'uppercase',
        color: 'text.secondary',
        strong: {
          color: 'text.primary'
        }
      },
      a: {
        color: 'text.secondary',
        textDecoration: 'none'
      },
      ul: {
        paddingLeft: 2,
        'li::marker': {
          content: "'+  '",
          color: 'text.secondary',
          fontWeight: 700
        }
      },
      img: {
        maxWidth: '100%'
      },
      figure: {
        marginY: 3
      },
      figcaption: {
        marginTop: 2,
        textAlign: 'center',
        fontStyle: 'italic'
      },
      hr: {
        marginY: 4,
        border: 'none',
        borderTopStyle: 'solid',
        borderTopWidth: 2,
        borderTopColor: 'divider'
      },
      table: {
        marginY: 4,
        width: '100%',
        borderCollapse: 'collapse'
      },
      'th, td': {
        padding: 1,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: 'divider'
      },
      th: {
        backgroundColor: 'primary.main',
        color: 'primary.contrastText'
      }
    }}
  />
);
